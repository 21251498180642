import Pristine from 'pristinejs';

export function validation() {

  const list = document.querySelectorAll("form");

  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  list.forEach(function(form){
    // create the pristine instance
    const pristine = new Pristine(form);

    form.addEventListener('submit', function (e) {
      e.preventDefault();

      // check if the form is valid
      let valid = pristine.validate(); // returns true or false

      if(valid){
        form.submit();
      }

    });
  });
};
