export function initTooltips() {
	let shownTooltips = [];

	const $tooltips = $('[data-toggle="tooltip"]');

	$tooltips.tooltip();

	$tooltips.on('shown.bs.tooltip', function (e) {
		const tooltip = e.currentTarget;
		shownTooltips.push(tooltip);
	});

	$tooltips.on('hidden.bs.tooltip', function (e) {
		const tooltip = e.currentTarget;
		shownTooltips = shownTooltips.filter((item) => item !== tooltip);
	});

	document.addEventListener('keydown', (e) => {
		if (e.key === 'Escape') {
			for (const shownTooltip of shownTooltips) {
				$(shownTooltip).tooltip('hide');
			}
			shownTooltips = [];
		}
	});

	const $accessibleTooltips = $('[data-toggle="tooltip"][data-trigger~="accessible-hover"]');

	$accessibleTooltips.on('mouseenter', function () {
		$(this).tooltip('show');
		$('.tooltip').on('mouseleave', () => {
			$(this).tooltip('hide');
		});
	});

	$accessibleTooltips.on('mouseleave', function () {
		setTimeout(() => {
			if (!$('.tooltip:hover').length) {
				$(this).tooltip('hide');
			}
		}, 300);
	});
}
