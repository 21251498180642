/* TODO: This is a legacy bundle for sites that do/can not load script type modules for now. */
// import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
import 'slick-carousel';
import 'lazysizes';

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/tooltip';

import { initTooltips } from './commons/_tooltips';
import { validation } from './commons/_validation';

$(document).ready(function () {
    initTooltips();
    validation();
});

// bootstrap and jQuery will not work on any html that gets injected into the page async
// therefore we have to init everything that might need interaction again
document.addEventListener('async-html-injected', (e) => {
    initTooltips();
});
